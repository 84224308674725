@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App{text-align:center;max-width:100% !important;position:relative;font-family:"Poppins",sans-serif;height:100vh;max-height:100vh;display:flex;flex-direction:column}.App .outer{width:100vw;height:100vh;background-size:80% auto;background-repeat:no-repeat;background-position:100% 80%}*{margin:0;padding:0;box-sizing:border-box}.App-logo{height:40vmin;pointer-events:none}.mainError{width:80%;max-width:600px;padding-top:60px;margin:auto}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}body{background-color:#f6f9fa !important;position:relative;overflow:auto}body .MuiTypography-root{font-family:"Poppins",sans-serif}a,a:visited{color:#06cccc}@media(max-width: 770px){.css-ozvxh8{display:unset !important;grid-template-rows:unset !important;overflow-y:unset !important;min-height:unset !important;width:100%}.css-3ra9v8{max-width:unset !important;height:unset !important;width:100%}}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@media screen and (max-width: 800px){.App .outer{height:100%;background-repeat:no-repeat;background-position:100% 80%}}iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"]{display:none}
